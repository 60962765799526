import React, { Component } from 'react';
import { container, wrapper } from './index.module.scss';

import PropTypes from 'prop-types';
import TextRenderer from './renderer';

class TextComponent extends Component {
  render() {
    const { id, text, lead } = this.props;

    return (
      <section id={id}>
        <div className={container}>
          <div className={wrapper} data-lead={lead}>
            <TextRenderer text={text?.text}/>
          </div>
        </div>
      </section>
    );
  }
}

TextComponent.propTypes = {
  text: PropTypes.string.isRequired,
  lead: PropTypes.bool
}

export default TextComponent;
